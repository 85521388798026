import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import store from '../store/index'

Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/index',
    children: [
      // {
      //   path: '/welcome',
      //   name: 'welcome',
      //   component: () => import('../views/welcome/welcome.vue')
      // },
      {
        path: '/index',
        name: 'index',
        component: () => import('../views/logo/index.vue')
      },
      {
        path: '/produce',
        name: 'produce',
        meta: {
          keepAlive: true // 需要缓存
        },
        component: () => import('../views/produce/produce.vue'),
       
      },
      {
        path: '/news',
        name: 'news',
        component: () => import('../views/news/news.vue')
      },
    
      {
        path: '/details',
        name: 'details',
        component: () => import('../views/produce/details.vue')
      },
      {
        path: '/newDetails',
        name: 'newDetails',
        component: () => import('../views/news/newDetails.vue')
      },
      {
        path: '/honor',
        name: 'honor',
        component: () => import('../views/honor/honor.vue')
      },
      // {
      //   path: '/Loginhome',
      //   name: 'Loginhome',
      //   component: () => import('../views/Loginhome.vue')
      // },
    ],
    
  },
//   {
//     path: '/Login',
//     component: () => import('../views/login.vue'),
//     meta: { title: '登录' }
// },


]

const router = new VueRouter({
  routes,
  mode:'history'
})
// router.beforeEach((to, from, next) => {
// let userInfo=store.state.userInfo
// console.log(to.path)
// if(to.path!=='/'&&(!userInfo || userInfo==null||!userInfo.users_Id)){

//     next('/')
// }else{
//   next();
// }
// })
export default router
