import { get,post} from './request.js'

//荣誉类型
export const getHonorType=p=>get('/API/honor/getHonorType',p);
// 荣誉列表
export const getHonorList=p=>get('/API/honor/getList',p);
// 新闻列表
export const getNewList=p=>get('/API/news/getList',p);
// 新闻详情
export const getnewDetail=p=>get('/API/news/getDetail',p);


// 产品列表
export const getProductList=p=>get('/API/product/getList',p);
//产品详情
export const getProductDetail=p=>get('/API/product/getDetail',p);
//获取
export const getCompanyInfo=p=>get('/API/company/select',p);


// 获取产品分类列表1
export const getProductTypeList=p=>get('/API/product/getTypeList',p);

// 登录
export const login=p=>post('/API/company/login',p);
// 获取用户信息
export const getInfo=p=>get('/API/company/getInfo',p);
// 官网提交联系人
export const contacInfo = p => post('/API/contacInfo/add', p);