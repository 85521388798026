import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import './assets/js/rem'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; // 默认主题
Vue.use(ElementUI);

Vue.use(ElementUI, {
  size: 'small'
});

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import VueQuillEditor from 'vue-quill-editor'
Vue.use(VueQuillEditor)

import VueTouch from 'vue-touch';
Vue.use(VueTouch, {
  name: 'v-touch'
});

import { List } from 'vant';
import { Cell } from 'vant';
Vue.use(List);
Vue.use(Cell);

VueTouch.config.swipe = {
  threshold: 10 //手指左右滑动触发事件距离
}
// import VueI18n from 'vue-i18n';
// import { messages } from './components/common/i18n';

// 路由拦截
// router.beforeEach((to, from, next) => {
//   let uaId = localStorage.getItem('uaId')
//   if (!uaId && to.path !== '/login') {
//     next('/login')
//     console.log('请先登录');
//     console.log(to.path)
//     } else {
//       next()
//     }
// })


import { Button,Popup,RadioGroup,Radio,Toast,Dialog,ActionSheet, Search,Image as VanImage,Lazyload } from 'vant';
import LuckDraw from 'vue-luck-draw'//九宫格抽奖

import 'amfe-flexible'
// import wx from 'weixin-js-sdk'
// Vue.use(wx)
Vue.use(LuckDraw)
Vue.use(Button).use(Popup).use(RadioGroup).use(Radio).use(Search).use(Toast).use(Dialog).use(ActionSheet).use(VanImage).use(Lazyload)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
