<template>
  <div class="home">
    <div class="container">
      <div class="left">
        <!-- <div class="left_img">
          <img @click="showDialog"
               :src="uaUrl"
               alt="" />
        </div> -->
        <!-- src=" https://dandaoipad.oss-cn-hangzhou.aliyuncs.com/dandaoipad/1876423871464.png" -->
        <img src="../assets/image/zhonghong.png"
             alt=""
             class="logo" />
        <div class="tabList">
          <div v-for="(item, index) in tabList"
               :key="index">
            <div @click="ToTab(item.value)"
                 :data-id="item.value">
              <div class="tabList_img">
                <!-- <img :src="currentValue == item.value ? item.icon2 : item.icon"
                     alt="" /> -->
              </div>
              <div class="tabList_title"
                   :style=" 
                currentValue == item.value ? 'color:#5AA8EE' : 'color:#ffffff'
              ">
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="right">
        <!-- <router-view></router-view>  -->
        <v-touch @swipeleft="swiperDirection(1)"
                 @swiperight="swiperDirection(2)"
                 class="v-touch wrapper"
                 :class="transClass">
          <div class="menu-container"
               ref="menuContainer">
            <keep-alive>
              <router-view v-if="$route.meta.keepAlive"></router-view>
            </keep-alive>
            <router-view v-if="!$route.meta.keepAlive"></router-view>
          </div>
        </v-touch>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible"
               width="32%"
               @close="closeDialog">
      <!-- <el-button type="text" @click="logout">退出登录</el-button> -->
      <img style="width: 100%;"
           src="../assets/image/icon/mite.png"
           alt="">
      <div class="erweima">
        <div class="qrcode"
             ref="canvas"
             style="width: 200px; height: 200px;"></div>
      </div>
      <el-button class="btn"
                 @click="logout"><span>退出登录</span></el-button>
      <!-- <div style="color: #409eff; margin-top: 20px;">欢迎扫码查看</div> -->
    </el-dialog>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import { getInfo } from '../utils/api'
export default {
  name: "Home",
  components: {},
  data () {
    return {
      transClass: "",
      isLoading: false,
      msg: "页面1",
      uaUrl: '',
      tabList: [
        // {
        //   icon: require("../assets/image/icon/welcome.png"),
        //   icon2: require("../assets/image/icon/welcome2.png"),
        //   value: 1,
        //   isSelect: true,
        //   title: "欢迎页",
        // },
        {
          icon: require("../assets/image/icon/logo.png"),
          icon2: require("../assets/image/icon/logo2.png"),
          value: 2,
          isSelect: true,
          title: "公司简介",
        },
        {
          icon: require("../assets/image/icon/produce.png"),
          icon2: require("../assets/image/icon/produce2.png"),
          value: 3,
          isSelect: true,
          title: "公司产品",
        },
        {
          icon: require("../assets/image/icon/honor.png"),
          icon2: require("../assets/image/icon/honor2.png"),
          value: 4,
          isSelect: true,
          title: "公司荣誉",
        },
        {
          icon: require("../assets/image/icon/news.png"),
          icon2: require("../assets/image/icon/news2.png"),
          value: 5,
          isSelect: true,
          title: "新闻事件",
        },
      ],
      currentValue: 1,
      dialogVisible: false,
    };
  },
  mounted () {
    if (localStorage.getItem("currentValue")) {
      this.currentValue = localStorage.getItem("currentValue");
    } else {
      this.currentValue = 1;
    }
    this.ftn();
  },
  created () {
    this.getInfo()
  },
  methods: {
    logout () {
      localStorage.clear();
      this.$router.push('/login');
    },
    swiperDirection (i) {
      //1向左滑2向右滑
      let _this = this;
      if (i == 1) {
        _this.transClass = "swipe-left";
        if (this.currentValue == 5) {
          this.currentValue = 1;
        } else {
          this.currentValue = this.currentValue + 1;
        }
        console.log(this.currentValue);
        this.ftn();
      } else {
        _this.transClass = "swipe-right";

        if (this.currentValue == 1) {
          this.currentValue = 5;
        } else {
          this.currentValue = this.currentValue - 1;
        }
        console.log(this.currentValue);

        this.ftn();
      }
      setTimeout(function () {
        _this.isLoading = true;
        _this.getdata();
      }, 500); //因为动画时间需要0.5s
    },
    getdata () {
      let _this = this;
      _this.msg = "";
      //可调接口，获取上一条/下一条数据后，再做以下操作
      _this.msg = "页面2";
      _this.isLoading = false; //不调接口效果可能不明显
      _this.transClass = "";
    },
    ToTab (e) {
      console.log(e);
      this.$set(this, "currentValue", e);
      localStorage.setItem("currentValue", this.currentValue);
      this.ftn();
    },
    getInfo () {
      getInfo({ uaId: localStorage.getItem('uaId') }).then((res) => {
        if (res.code == 20000) {
          console.log(res.data);
          this.uaUrl = res.data.uaUrl
        }
      })
    },
    // showDialog() {
    //   this.dialogVisible = true;
    //   console.log(9999);
    // },

    // 生成二维码
    showDialog () {
      let uaId = localStorage.getItem('uaId')
      console.log(uaId);
      this.dialogVisible = true;
      console.log(1111111111);
      let opts = {
        errorCorrectionLevel: 'H', //容错级别
        type: 'image/png', //生成二维码类型
        quality: 0.3, //二维码质量
        width: 200, //宽度
        height: 200, //高度
        text: 'https://zhao.dandaoint.ren/iPhoneH5/#?uaId=' + uaId, //二维码内容
        color: {
          dark: '#333333',
          light: '#fff' //背景色}
        }
      };
      this.$nextTick(() => {
        // QRCode.toCanvas(this.$refs.canvas, row.vsId, opts);
        new QRCode(this.$refs.canvas, opts)
      })
    },
    // 清楚二维码
    closeDialog () {
      console.log('清除二维码');
      this.$refs.canvas.innerHTML = "";
    },
    // onSwipeLeft() {
    //   console.log(this.currentValue);
    //   if (this.currentValue == 1) {
    //     this.currentValue = 5;
    //   } else {
    //     this.currentValue = this.currentValue - 1;
    //   }
    //   // this.ftn()
    // },
    // onSwipeRight() {
    //   console.log(this.currentValue);

    //   if (this.currentValue == 5) {
    //     this.currentValue = 1;
    //   } else {
    //     this.currentValue = this.currentValue + 1;
    //   }

    //   //  this.ftn()
    // },

    ftn () {
      if (this.currentValue == 1) {
        this.$router.push("/");
      } else if (this.currentValue == 2) {
        this.$router.push("/index");
      } else if (this.currentValue == 3) {
        this.$router.push("/produce");
      } else if (this.currentValue == 4) {
        // this.$router.push("/produce");
        this.$router.push("/honor");
      } else if (this.currentValue == 5) {
        this.$router.push("/news");
      }
    },
  },
};
</script>
<style scoped>
.v-touch {
  touch-action: pan-y !important;
  /* //解决页面垂直滚动失效问题 */
}

.hello,
.wrapper,
.menu-container,
.modal-loading {
  /* height: 100%; */
}
.btn {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 140px;
  height: 34px;
  border-radius: 100px;
  background: #409eff;
}
.btn span {
  font-size: 14px;
  /* line-height: 32px; */
  font-weight: bold;
  text-align: center;
  color: #ffffff;
}
.wrapper {
  /* padding-top: 100px;
  font-size: 20px;
  background-color: lightcoral;
  color: #ffffff; */
}
/deep/ .el-dialog__header {
  padding: 0 !important;
}
/deep/ .el-dialog__body {
  padding: 0 !important;
}
/deep/ .el-icon-close:before {
  content: "\e6db";
  color: black;
  position: relative;
  left: 13px;
  top: -10px;
}
.modal-loading {
  position: fixed;
  top: 0;
  left: 0;
  color: #ffffff;
  background-color: rgba(1, 1, 1, 0.8);
}

.loadingTxt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.swipe-left {
  transition: all 0.5s;
  transform: translateX(-100%);
}

.swipe-right {
  transition: all 0.5s;
  transform: translateX(100%);
}
.container {
  background: #fff;
  width: 100%;
  height: 100vh;
}
.left {
  background: #0b6ba8;

  width: 100%;
  height: 12%;
  /* background: #f3f4f5; */
  padding: 35px 0 0 0;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 256px 0 90px;
  /* box-sizing: border-box; */
  font-size: 18px;
}
.logo {
  width: 150px;
  height: 50px;
}
.left_img {
  width: 40px;
  height: 40px;
  text-align: center;
  margin: 0 auto;
}
.left_img img {
  width: 100%;
  height: 100%;
  /* border-radius: 50%; */
}

.tabList {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  margin: 20px auto;
  margin-left: 100px;
}
.tabList div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tabList_title {
  font-size: 18px;
  color: #ffffff;
}
.tabList_img {
  width: 30px;
  height: 30px;
}
.tabList_img img {
  width: 100%;
  height: 100%;
}
.right {
  width: 100%;
  height: 85%;
  position: absolute;
  top: 0;
  right: 0;
  /* margin-top: 6%; */
}
.erweima {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.erweima img {
  width: 100%;
  height: 100%;
  margin: 10px auto;
}
</style>
