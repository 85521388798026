<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  created() {
    //在页面加载时读取localStorage里的状态信息
    if (localStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(localStorage.getItem("store"))
        )
      );
    }

    //在页面刷新时将vuex里的信息保存到localStorage里
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
};
</script>
<style>
@import "../ipad/css/quill.bubble.css";
@import "../ipad/css/quill.core.css";
@import "../ipad/css/quill.snow.css";
@import url('../src/assets/common.css');
</style>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.html {
  // width:100%;
}
p {
  font-size: 14px;
  line-height: 24px;
  text-align: left;
}

p img {
  width: 100%;
  height: auto;
  display: block;
  margin: 10px auto;
}
span {
  font-size: 14px;
}
</style>
